import React, {useEffect, useRef, useState} from 'react';
import {MySpinner, Input, DropDown} from 'components/atoms';
import {Button as AntBtn, Radio, Switch} from 'antd';
import {Dropdown} from 'antd';
import {
  Row,
  Button,
  Form,
  CardBody,
  Card,
  Spinner,
  FormGroup,
  Col,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {EditorState, convertToRaw} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import CenterFormApi from 'services/api/CenterFormApi';
import history from 'services/history';
import classnames from 'classnames';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {
  checkEmptyProperties,
  findEmptyField,
  findObjectsWithEmptyFields,
  mealTfrench,
  mealToId,
  showToast,
  userStatus,
} from 'shared/extra';
// import DropDown from 'components/atoms/DropDown2';
import {orange} from '@mui/material/colors';
import ButtonAntDesign from 'components/atoms/AntdButton';
import {CloseCircleOutlined, MinusOutlined} from '@ant-design/icons';

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

const mealTypes = [
  {id: 'losing weight', name: 'Perte de poids'},
  {id: 'improving health', name: 'Amélioration de santé et d’hygiène'},
  {id: 'vacation', name: 'Mode Vacances'},
];

const mealPlans = [
  {id: 'daily', name: 'Quotidien'},
  {id: 'weekly', name: 'Hebdomadaire'},
];

const genders = [
  {id: 'male', name: 'Mâle'},
  {id: 'female', name: 'Femelle'},
  {id: 'both', name: 'Les deux'},
];
const itemTypes = [
  {id: 'carbs', name: 'Carbs'},
  {id: 'mfe', name: 'VPO'},
  {id: 'normal', name: 'Ordinaire'},
];
const dependings = [
  {id: 'quantity', name: 'Quantité'},
  {id: 'weight', name: 'Poids'},
];
const functions = [
  {id: 'multiply', name: 'Multiplier *'},
  {id: 'add', name: 'Plus +'},
  {id: 'subtract', name: 'Soustraire -'},
  {id: 'divide', name: 'Diviser /'},
];

const weekNumbers = [];
for (let i = 1; i <= 53; i++) {
  weekNumbers.push({id: i, name: i});
}
const weights = [
  {id: 50, value: '', weightFunction: ''},
  {id: 60, value: '', valueType: '', weightFunction: ''},
  {id: 70, value: '', valueType: '', weightFunction: ''},
  {id: 80, value: '', valueType: '', weightFunction: ''},
  {id: 90, value: '', valueType: '', weightFunction: ''},
  {id: 100, value: '', valueType: '', weightFunction: ''},
  {id: 120, value: '', weightFunction: ''},
];
const mealObject = {
  timeOfDay: '',
  mealType: '',
  description: '',
  notes: '',
  gender: '',
};

const examples = [];

for (let i = 0; i < 7; i++) {
  examples.push({
    mealBreakFast: [
      {
        ...mealObject,
        weights: [...weights],
        timeOfDay: 'breakfast',
        dayNumber: i + 1,
      },
    ],
    mealLunch: [
      {
        ...mealObject,
        weights: [...weights],
        timeOfDay: 'lunch',
        dayNumber: i + 1,
      },
    ],
    mealDinner: [
      {
        ...mealObject,
        weights: [...weights],
        timeOfDay: 'dinner',
        dayNumber: i + 1,
      },
    ],
    mealSnacks: [
      {
        ...mealObject,
        weights: [...weights],
        timeOfDay: 'snacks',
        dayNumber: i + 1,
      },
    ],
  });
}

const MealComponent = ({
  show,
  SetmealPdfOuter,
  mealData,
  type,
  customMenuUserId,
  userMenu,
  onSubmit,
  loadingDataEdit,
  scrollToId,
  setShowMealData
  // scrollToTop
}) => {
  show ? (show = true) : (show = false);
  const [countError, setcountError] = useState(0);
  const [loading, setLoading] = useState(false);
  const [eroors, setEroors] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [photos, setPhotos] = useState([]);
  const hiddenRef = useRef();
  const [selectedOption, setSelectedOption] = useState(0);
  const [showExamples, setShowExamples] = useState(false);
  const [showBreakFast, setShowBreakFast] = useState(false);
  const [showLunch, setShowLunch] = useState(false);
  const [showDinner, setShowDinner] = useState(false);
  const [showSnacks, setShowSnacks] = useState(false);
  const [showTextAraa, setshowTextAraa] = useState(false);
  const [showError, setshowError] = useState(false);
  const scrollToTopRef = useRef(null);
  const [bodyTextArea, setbodyTextArea] = useState('');
  const [mealPdf, setMealPdf] = useState(mealData?.pdf_url);
  const newMeal = {
    name: '',
    week: '',
    mealType: '',
    pdf_url: '',
    mealPlan: '',
    recommendations: EditorState.createEmpty(),
    otherOptions: [
      [
        {
          mealBreakFast: [
            {
              ...mealObject,
              weights: [...weights],
              timeOfDay: 'breakfast',
              dayNumber: 1,
            },
          ],
          mealLunch: [
            {
              ...mealObject,
              weights: [...weights],
              timeOfDay: 'lunch',
              dayNumber: 1,
            },
          ],
          mealDinner: [
            {
              ...mealObject,
              weights: [...weights],
              timeOfDay: 'dinner',
              dayNumber: 1,
            },
          ],
          mealSnacks: [
            {
              ...mealObject,
              weights: [...weights],
              timeOfDay: 'snacks',
              dayNumber: 1,
            },
          ],
        },
      ],
    ],
    examples: [...examples],
  };

  const openSection = section => {
    // console.log(section);
    switch (section) {
      case 'gri-': {
        setShowSnacks(true);
        setShowDinner(false);
        setShowLunch(false);
        setShowBreakFast(false);
        break;
      }
      case 'dej-': {
        setShowSnacks(false);
        setShowDinner(false);
        setShowLunch(true);
        setShowBreakFast(false);
        break;
      }
      case 'din-': {
        setShowSnacks(false);
        setShowDinner(true);
        setShowLunch(false);
        setShowBreakFast(false);
        break;
      }
      case 'ptt-': {
        setShowSnacks(false);
        setShowDinner(false);
        setShowLunch(false);
        setShowBreakFast(true);
        break;
      }
      default: {
        setShowSnacks(false);
        setShowDinner(false);
        setShowLunch(false);
        setShowBreakFast(false);
        break;
      }
    }
  };

  const [meal, setMeal] = useState({...newMeal});
  useEffect(() => {
    if (mealData) {
      setMeal(mealData);
    }
  }, [mealData]);

  // if (element) {
  //   element.scrollIntoView({
  //     top: 150,
  //     // behavior: 'smooth'
  //   });

  // const otherOptions = [
  //   [
  //     {
  //       mealBreakFast: [{description: '1', note: '1', gender: '1'}],
  //       mealLunch: [{description: '1', note: '1', gender: '1'}],
  //       mealDinner: [{description: '1', note: '1', gender: '1'}],
  //       mealSnacks: [{description: '1', note: '1', gender: ''}],
  //     },
  //     {
  //       mealBreakFast: [{description: '1', note: '1', gender: '1'}],
  //       mealLunch: [{description: '', note: '1', gender: '1'}],
  //       mealDinner: [{description: '1', note: '1', gender: '1'}],
  //       mealSnacks: [{description: '1', note: '1', gender: '1'}],
  //     },
  //     {
  //       mealBreakFast: [{description: '1', note: '1', gender: '1'}],
  //       mealLunch: [{description: '1', note: '1', gender: '1'}],
  //       mealDinner: [{description: '1', note: '1', gender: '1'}],
  //       mealSnacks: [{description: '', note: '', gender: '1'}],
  //     },
  //   ],
  // ];
  // Function to check if a value is empty or null
  const isEmptyOrNull = value =>
    value === '' || value === null || value === undefined;
  const checkMealTypes = (otherOptions, exmple) => {
    let count = 0;
    let firstindex = 0;
    otherOptions.forEach((option, j) => {
      option.forEach((meals, i) => {
        Object.keys(meals).forEach(mealType => {
          meals[mealType].forEach((meal, index) => {
            if (isEmptyOrNull(meal.description)) {
              count++;
              setEroors(prev => [
                ...prev,
                {
                  message: `Description de la section ${mealTfrench(
                    mealType,
                  )} pour le jour ${i + 1} option ${j + 1}`,
                  index: i,
                  page: j,
                  section: mealToId(mealType),
                },
              ]);
              // // console.log("error",` SVP remplir le shamp description de la section ${mealTfrench(mealType)} pour le jour ${i+1} `,null,"bottom-left");
            }

            // if (isEmptyOrNull(meal.note)) {
            //   count++;

            //   setEroors(prev => [
            //     ...prev,
            //     `Note  de la section ${mealTfrench(mealType)} pour le jour ${
            //       i + 1
            //     }`,
            //   ]);
            // }
            if (isEmptyOrNull(meal.gender)) {
              count++;
              setEroors(prev => [
                ...prev,
                {
                  message: `Genre de la section ${mealTfrench(
                    mealType,
                  )} pour le jour ${i + 1} option ${j + 1}`,
                  index: i,
                  page: j,
                  section: mealToId(mealType),
                },
              ]);
            }
            if (isEmptyOrNull(meal.mealType)) {
              count++;
              setEroors(prev => [
                ...prev,
                {
                  message: `Type d'élément de la section ${mealTfrench(
                    mealType,
                  )} pour le jour ${i + 1} option ${j + 1}`,
                  index: i,
                  page: j,
                  section: mealToId(mealType),
                },
              ]);
            }
            count == 1 ? (firstindex = index) : (firstindex = 0);
          });
        });
      });
    });
    setcountError(count);
    // // console.log(count);
    return {count, firstindex};
  };
  // // console.log(eroors);
  // Function to check each meal type for empty or null fields
  const checkMealTypeshebdo = otherOptions => {
    let count = 0;
    let firstindex = 0;
    otherOptions.forEach((option, i) => {
      option.forEach(meals => {
        Object.keys(meals).forEach(mealType => {
          meals[mealType].forEach((meal, index) => {
            if (isEmptyOrNull(meal.description)) {
              count++;
              // showToast("error",
              setEroors(prev => [
                ...prev,
                {
                  message: `Description de la section ${mealTfrench(
                    mealType,
                  )} pour l'option ${index + 1} page ${i + 1} `,
                  index: i,
                  section: mealToId(mealType),
                },
              ]);
            }
            // if (isEmptyOrNull(meal.note)) {
            //   count++;
            //   setEroors(prev => [
            //     ...prev,
            //     `Note  de la section ${mealTfrench(mealType)} pour l"option ${
            //       index + 1
            //     } page ${i + 1}`,
            //   ]);
            // }
            if (isEmptyOrNull(meal.gender)) {
              count++;
              setEroors(prev => [
                ...prev,
                {
                  message: `Genre de la section ${mealTfrench(
                    mealType,
                  )} pour l'option ${index + 1} page ${i + 1}`,
                  index: i,
                  section: mealToId(mealType),
                },
              ]);
            }
            if (isEmptyOrNull(meal.mealType)) {
              count++;
              setEroors(prev => [
                ...prev,
                {
                  message: `Type d'élément de la section ${mealTfrench(
                    mealType,
                  )} pour l'option ${index + 1} page ${i + 1} `,
                  index: i,
                  section: mealToId(mealType),
                },
              ]);
            }
            count == 1 ? (firstindex = index) : (firstindex = 0);
          });
        });
      });
    });
    setcountError(prev => prev + count);
    return {count, firstindex};
  };

  const submitForm = async e => {
    // console.log(show);
    
    e.preventDefault();
    if (show) {
      // console.log(show);
      
      setShowMealData(false)
    }else
  {
    if (
      !meal.name ||
      !meal.mealPlan ||
      (!meal.week && meal.mealType.id != 'vacation') ||
      !meal.mealType
    ) {
      const m1 = !meal.name ? 'Nom de plan alimentaire, ' : '';
      const m2 = !meal.mealPlan ? 'Fréquence, ' : '';
      const m3 = !meal.mealType ? 'Programme alimentaire, ' : '';
      const m4 = !meal.week ? 'Nombre de semaine, ' : '';
      const data = [m1, m2, m3, m4];
      const m5 = data.filter(d => d != '').length > 2 ? 'sont ' : 'est ';
      const message = m1 + m2 + m3 + m4 + m5 + 'obligatoire!';
      alert(message);
    } else {
      // console.log('here');

      // console.log('here2');
      if (userMenu) {
        onSubmit(meal);
        return;
      }
      setLoading(true);
      // // console.log({
      //   meal: {
      //     ...meal,
      //     type,
      //     pdf_url: mealPdf ? mealPdf : null,
      //     recommendations: draftToHtml(
      //       convertToRaw(meal.recommendations.getCurrentContent()),
      //     ),
      //   },
      // });
      let countheb, countq;
      if (meal.mealPlan.name == 'Hebdomadaire') {
        countheb = checkMealTypeshebdo(meal?.otherOptions);
        toggle(countheb?.firstindex);
      } else if (meal.mealPlan.name == 'Quotidien') {
        // // console.log();

        countq = checkMealTypes(meal?.otherOptions);
        toggle(countq?.firstindex);
      }
      // console.log('eroors', eroors);
      // if (countheb?.count > 0 || countq?.count > 0) {
      //   setshowError(true);
      // } else
      // {
      try {
        if (!mealData) {
          const res = await CenterFormApi.post('/createMeal', {
            meal: {
              ...meal,
              type,
              intermidiain,
              recommendations: draftToHtml(
                convertToRaw(meal.recommendations.getCurrentContent()),
              ),
            },
          });
          if (res.data && photos.length > 0) {
            const formData = new FormData();
            formData.append('file', photos[0]);
            formData.append('meal_id', res.data.data._id);
            const res = await CenterFormApi.post('/uploadPdfMeal', formData);
          }
        } else {
          const res = await CenterFormApi.put('/editMeal', {
            meal: {
              ...meal,
              type,
              intermidiain,
              pdf_url: mealPdf ? mealPdf : null,
              recommendations: draftToHtml(
                convertToRaw(meal.recommendations.getCurrentContent()),
              ),
            },
          });
          if (res.data && photos.length > 0) {
            const formData = new FormData();
            formData.append('file', photos[0]);
            // console.log(meal?._id || meal?.id);
            formData.append('meal_id', meal?.id);
            for (const pair of formData.entries()) {
              // console.log(pair[0], pair[1]);
            }
            const data = formData;
            const res = await CenterFormApi.post('/uploadPdfMeal', formData);
          }
        }

        setLoading(false);
        history.push(type === 'premium' ? '/meals-premium' : '/meals-standard');
      } catch (e) {
        setActiveTab(4);
        // console.log('message   => ', e.message);
        // toggle(1);
        setLoading(false);
      }
    }

      setLoading(false);
      }
  };

  const onInputChange = (e, target) => {
    setMeal(meal => {
      if (target === 'mealPlan') {
        switchMealPlan(e.id);
      }
      return {
        ...meal,
        [target]: e.target ? e.target.value : e,
      };
    });
  };

  const switchMealPlan = id => {
    toggle(0);
    for (let x = 0; x < meal.otherOptions.length; x++) {
      if (id === 'daily' && meal.otherOptions[x].length < 7) {
        for (let i = 0; i < 6; i++) {
          meal.otherOptions[x].push({
            mealBreakFast: [
              {
                ...mealObject,
                weights: [...weights],
                timeOfDay: 'breakfast',
                dayNumber: i + 2,
              },
            ],
            mealLunch: [
              {
                ...mealObject,
                weights: [...weights],
                timeOfDay: 'lunch',
                dayNumber: i + 2,
              },
            ],
            mealDinner: [
              {
                ...mealObject,
                weights: [...weights],
                timeOfDay: 'dinner',
                dayNumber: i + 2,
              },
            ],
            mealSnacks: [
              {
                ...mealObject,
                weights: [...weights],
                timeOfDay: 'snacks',
                dayNumber: i + 2,
              },
            ],
          });
        }
      } else if (id === 'weekly' && meal.otherOptions[x].length !== 1) {
        meal.otherOptions[x] = meal.otherOptions[x].filter(
          (item, index) => index === 0,
        );
      }
    }
  };

  const changeNav = (i, v) => {
    if (v) {
      setSelectedOption(i - 1);
    } else {
      setSelectedOption(i);
    }
  };

  const addOption = () => {
    meal.otherOptions.push([
      {
        mealBreakFast: [
          {
            ...mealObject,
            weights: [...weights],
            timeOfDay: 'breakfast',
            dayNumber: 1,
          },
        ],
        mealLunch: [
          {
            ...mealObject,
            weights: [...weights],
            timeOfDay: 'lunch',
            dayNumber: 1,
          },
        ],
        mealDinner: [
          {
            ...mealObject,
            weights: [...weights],
            timeOfDay: 'dinner',
            dayNumber: 1,
          },
        ],
        mealSnacks: [
          {
            ...mealObject,
            weights: [...weights],
            timeOfDay: 'snacks',
            dayNumber: 1,
          },
        ],
      },
    ]);
    // // console.log(meal?.mealPlan?.id);
    switchMealPlan(meal?.mealPlan?.id);
    setMeal({...meal});
    // // console.log(meal?.otherOptions?.length);
    setSelectedOption(meal?.otherOptions?.length - 1);
  };

  const deleteOption = i => {
    try {
      // // console.log("1",meal.otherOptions);
      meal.otherOptions.pop();
      // // console.log("2",meal.otherOptions);
      switchMealPlan(meal.mealPlan.id);
      setMeal({...meal});

      // setActiveTab(0)
      // // console.log(i);
    } catch (e) {
      // console.log(e);
    }
  };
  // // console.log("selectedOption",selectedOption);

  const onBreakFastMealChange = (value, target, index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealBreakFast[index] = {
          ...meal.examples[dayIndex].mealBreakFast[index],
          [target]: value,
        };
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealBreakFast[index] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealBreakFast[index],
          [target]: value,
        };
      }
      return {...meal};
    });
  };
  const onBreakFastWeightChange = (
    value,
    target,
    index1,
    index2,
    dayIndex,
    example,
  ) => {
    // // console.log('ff', target);
    // // console.log('ff', value);
    setMeal(meal => {
      if (example) {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.examples[dayIndex].mealBreakFast[index1].weights[index2] = {
            ...meal.examples[dayIndex].mealBreakFast[index1].weights[index2],

            weightFunction: '',
          };

          // weightFunction
        }

        meal.examples[dayIndex].mealBreakFast[index1].weights[index2] = {
          ...meal.examples[dayIndex].mealBreakFast[index1].weights[index2],
          [target]: value,
        };
      } else {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.otherOptions[selectedOption][dayIndex].mealBreakFast[
            index1
          ].weights[index2] = {
            ...meal.otherOptions[selectedOption][dayIndex].mealBreakFast[index1]
              .weights[index2],
            weightFunction: '',
          };

          // weightFunction
        }
        meal.otherOptions[selectedOption][dayIndex].mealBreakFast[
          index1
        ].weights[index2] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealBreakFast[index1]
            .weights[index2],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  // console.log(meal);
  const addNewBreakFastInput = (dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealBreakFast.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'breakfast',
        });
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealBreakFast.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'breakfast',
        });
      }
      return {...meal};
    });
  };
  const onLunchMealChange = (value, target, index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealLunch[index] = {
          ...meal.examples[dayIndex].mealLunch[index],
          [target]: value,
        };
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealLunch[index] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealLunch[index],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const onLunchWeightChange = (
    value,
    target,
    index1,
    index2,
    dayIndex,
    example,
  ) => {
    setMeal(meal => {
      if (example) {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.examples[dayIndex].mealLunch[index1].weights[index2] = {
            ...meal.examples[dayIndex].mealLunch[index1].weights[index2],
            weightFunction: '',
          };
        }
        meal.examples[dayIndex].mealLunch[index1].weights[index2] = {
          ...meal.examples[dayIndex].mealLunch[index1].weights[index2],
          [target]: value,
        };
      } else {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.otherOptions[selectedOption][dayIndex].mealLunch[index1].weights[
            index2
          ] = {
            ...meal.otherOptions[selectedOption][dayIndex].mealLunch[index1]
              .weights[index2],
            weightFunction: '',
          };
        }
        meal.otherOptions[selectedOption][dayIndex].mealLunch[index1].weights[
          index2
        ] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealLunch[index1]
            .weights[index2],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const addNewLunchInput = (dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealLunch.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'lunch',
        });
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealLunch.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'lunch',
        });
      }

      return {...meal};
    });
  };
  const onDinnerMealChange = (value, target, index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealDinner[index] = {
          ...meal.examples[dayIndex].mealDinner[index],
          [target]: value,
        };
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealDinner[index] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealDinner[index],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const onDinnerWeightChange = (
    value,
    target,
    index1,
    index2,
    dayIndex,
    example,
  ) => {
    setMeal(meal => {
      if (example) {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.examples[dayIndex].mealDinner[index1].weights[index2] = {
            ...meal.examples[dayIndex].mealDinner[index1].weights[index2],
            weightFunction: '',
          };
        }
        meal.examples[dayIndex].mealDinner[index1].weights[index2] = {
          ...meal.examples[dayIndex].mealDinner[index1].weights[index2],
          [target]: value,
        };
      } else {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.otherOptions[selectedOption][dayIndex].mealDinner[
            index1
          ].weights[index2] = {
            ...meal.otherOptions[selectedOption][dayIndex].mealDinner[index1]
              .weights[index2],
            weightFunction: '',
          };
        }
        meal.otherOptions[selectedOption][dayIndex].mealDinner[index1].weights[
          index2
        ] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealDinner[index1]
            .weights[index2],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const addNewDinnerInput = (dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealDinner.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'dinner',
        });
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealDinner.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'dinner',
        });
      }
      return {...meal};
    });
  };
  const onSnacksMealChange = (value, target, index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealSnacks[index] = {
          ...meal.examples[dayIndex].mealSnacks[index],
          [target]: value,
        };
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealSnacks[index] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealSnacks[index],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const onSnacksWeightChange = (
    value,
    target,
    index1,
    index2,
    dayIndex,
    example,
  ) => {
    setMeal(meal => {
      if (example) {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.examples[dayIndex].mealSnacks[index1].weights[index2] = {
            ...meal.examples[dayIndex].mealSnacks[index1].weights[index2],
            weightFunction: '',
          };
        }
        meal.examples[dayIndex].mealSnacks[index1].weights[index2] = {
          ...meal.examples[dayIndex].mealSnacks[index1].weights[index2],
          [target]: value,
        };
      } else {
        if (target === 'valueType' && value.name != 'Poids') {
          meal.otherOptions[selectedOption][dayIndex].mealSnacks[
            index1
          ].weights[index2] = {
            ...meal.otherOptions[selectedOption][dayIndex].mealSnacks[index1]
              .weights[index2],
            weightFunction: '',
          };
        }
        meal.otherOptions[selectedOption][dayIndex].mealSnacks[index1].weights[
          index2
        ] = {
          ...meal.otherOptions[selectedOption][dayIndex].mealSnacks[index1]
            .weights[index2],
          [target]: value,
        };
      }

      return {...meal};
    });
  };
  const addNewSnacksInput = (dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealSnacks.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'snacks',
        });
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealSnacks.push({
          ...mealObject,
          weights: [...weights],
          dayNumber: dayIndex + 1,
          timeOfDay: 'snacks',
        });
      }
      return {...meal};
    });
  };
  const removeDinnerItem = (index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealDinner = meal.examples[
          dayIndex
        ].mealDinner.filter((item, index2) => index !== index2);
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealDinner =
          meal.otherOptions[selectedOption][dayIndex].mealDinner.filter(
            (item, index2) => index !== index2,
          );
      }

      return {...meal};
    });
  };
  const removeSnacksItem = (index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealSnacks = meal.examples[
          dayIndex
        ].mealSnacks.filter((item, index2) => index !== index2);
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealSnacks =
          meal.otherOptions[selectedOption][dayIndex].mealSnacks.filter(
            (item, index2) => index !== index2,
          );
      }

      return {...meal};
    });
  };
  const removeLunchItem = (index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealLunch = meal.examples[
          dayIndex
        ].mealLunch.filter((item, index2) => index !== index2);
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealLunch =
          meal.otherOptions[selectedOption][dayIndex].mealLunch.filter(
            (item, index2) => index !== index2,
          );
      }

      return {...meal};
    });
  };
  const removeBreakfastItem = (index, dayIndex, example) => {
    setMeal(meal => {
      if (example) {
        meal.examples[dayIndex].mealBreakFast = meal.examples[
          dayIndex
        ].mealBreakFast.filter((item, index2) => index !== index2);
      } else {
        meal.otherOptions[selectedOption][dayIndex].mealBreakFast =
          meal.otherOptions[selectedOption][dayIndex].mealBreakFast.filter(
            (item, index2) => index !== index2,
          );
      }

      return {...meal};
    });
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const breakFastDrag = result => {
    const newArray = [];
    meal.otherOptions[selectedOption][activeTab].mealBreakFast.move(
      parseInt(result.draggableId),
      result.destination.index,
    );
    setMeal(meal => ({...meal}));
  };
  const dinnerDrag = result => {
    const newArray = [];
    meal.otherOptions[selectedOption][activeTab].mealDinner.move(
      parseInt(result.draggableId),
      result.destination.index,
    );
    setMeal(meal => ({...meal}));
  };
  const lunchDrag = result => {
    const newArray = [];
    meal.otherOptions[selectedOption][activeTab].mealLunch.move(
      parseInt(result.draggableId),
      result.destination.index,
    );
    setMeal(meal => ({...meal}));
  };
  const snacksDrag = result => {
    const newArray = [];
    meal.otherOptions[selectedOption][activeTab].mealSnacks.move(
      parseInt(result.draggableId),
      result.destination.index,
    );
    setMeal(meal => ({...meal}));
  };

  const handleClickForUpload = () => {
    hiddenRef.current.click();
  };

  const handleUpload = e => {
    const files = e.target.files;
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    setPhotos(chosenFiles);
    setMealPdf(chosenFiles[0]);
    if (customMenuUserId) {
      SetmealPdfOuter(chosenFiles[0]);
    } else {
    }
  };
  // // console.log(mealData.intermidiain);

  const [intermidiain, setintermidiain] = useState(
    mealData?.intermidiain ? mealData?.intermidiain : false,
  );

  const onChange = e => {
    // console.log(`radio checked:${e.target.value}`);
    setintermidiain(e.target.value);
  };

  // // console.log(mealData);
  // // console.log(mealPdf);
  return (
    <Row

    // ref={scrollToTopRef}
    >
      {/* <MySpinner active={loadingData} /> */}
      <Card className="card-container">
        <label className="card-label2">Détails du plan alimentaire</label>
        <CardBody>
          <Form className="ecs-form" autoComplete="off" onSubmit={submitForm}>
            <FormGroup row>
              <Col
                style={{
                  zIndex: 11,
                }}
                sm={12}>
                <div style={{
                
                  display: "flex", gap: 5, marginBottom: '5px'
                }}>
                
                  <p
                    style={{
                      // margin: '0 0 15px 10px',
                      backgroundColor: 'red',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                    }}></p>
                  <span style={{fontSize:"13px" ,marginLeft:0}}>  Champ(s) vide(s) !</span>
                  {' '}
                  {" "} 
                </div>

                <div style={{'align-self': 'end', marginBottom: '50px'}}>
                  <Radio.Group onChange={onChange} defaultValue={intermidiain}>
                    <Radio.Button value={true}>intermédiaire</Radio.Button>
                    <Radio.Button value={false}>Sequentiel</Radio.Button>
                  </Radio.Group>
                </div>

                <Row>
                  <Col sm={4}>
                    <div className="form-group">
                      <Input
                        value={meal.name}
                        pattern=".*\S+.*"
                        onChange={e => onInputChange(e, 'name')}
                        label="Nom du plan alimentaire*"
                        type="text"
                        required={false}
                      />
                    </div>
                  </Col>
                  <Col style={{zIndex: 999}} sm={4}>
                    <div className="form-group">
                      <DropDown
                        value={meal.mealType}
                        onClick={item => onInputChange(item, 'mealType')}
                        label="Programme alimentaire*"
                        data={mealTypes}
                      />
                    </div>
                  </Col>

                  <Col style={{zIndex: 11}} sm={4}>
                    <div className="form-group">
                      <DropDown
                        value={meal.mealPlan}
                        onClick={item => onInputChange(item, 'mealPlan')}
                        data={mealPlans}
                        label="Fréquence*"
                      />
                    </div>
                  </Col>

                  {!userMenu && meal.mealType?.id !== 'vacation' ? (
                    <Col style={{zIndex: 11}} sm={4}>
                      <div className="form-group">
                        <DropDown
                          value={meal.week}
                          onClick={item => onInputChange(item, 'week')}
                          label="Nombre Semaine*"
                          data={weekNumbers}
                        />
                      </div>
                    </Col>
                  ) : null}

                  {!show && (
                    <Col sm={4}>
                      <div className="form-group">
                        <Input
                          value={
                            photos?.length == 0
                              ? mealPdf
                                ? mealPdf
                                : ''
                              : photos?.map(file => file.name + ' ')
                          }
                          style={{cursor: 'pointer'}}
                          pattern=".*\S+.*"
                          onClick={handleClickForUpload}
                          label="Documents PDF "
                          type="text"
                          placeholder=" "
                        />
                        <input
                          // multiple
                          onChange={handleUpload}
                          value={''}
                          accept="application/pdf"
                          ref={hiddenRef}
                          type="file"
                          style={{display: 'none'}}
                        />
                        {mealPdf && (
                          <div
                            style={{
                              cursor: 'pointer',
                              margin: '0 0 7px 10px',
                              color: 'red',
                              fontSize: '30px',
                            }}>
                            <CloseCircleOutlined
                              title="Supprimer document"
                              onClick={() => {
                                setMealPdf('');
                                setPhotos([]);
                                if (customMenuUserId) {
                                  SetmealPdfOuter(null);
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Col>
                  )}

                  {/* <Col style={{zIndex: 11}} sm={4}>
                    <div className="form-group">
                      <DropDown
                        value={meal.week}
                        onClick={item => onInputChange(item, 'week')}
                        label="Nombre Semaine*"
                        data={weekNumbers}
                      />
                    </div>
                  </Col> */}

                  <div className="align-right">
                    <div>{showExamples ? <p>Exemples</p> : <p>Repas</p>}</div>

                    <div
                      style={{
                        // border: '1px solid red',
                        justifyContent: 'flex-end',
                      }}>
                      <AntBtn
                        type="primary"
                        onClick={() => setShowExamples(false)}
                        className="profile-btn-white"
                        style={{
                          width: showExamples && '80px',
                          backgroundColor: !showExamples ? 'orange' : 'white',
                        }}
                        // size="lg"
                      >
                        Options
                      </AntBtn>
                      <AntBtn
                        type="primary"
                        style={{
                          width: !showExamples && '80px',
                          backgroundColor: showExamples ? 'orange' : 'white',
                        }}
                        onClick={() => setShowExamples(true)}
                        className="profile-btn-white"
                        // size="lg"
                      >
                        Exemples
                      </AntBtn>
                    </div>
                  </div>
                  {/* {showExamples&&
                      <Col style={{zIndex: 11}} sm={4}>
                      <div className="form-group">
                        <DropDown
                          // value={meal.week}
                          // onClick={item => onInput(item, 'week')}
                          label="Semaine"
                          data={weekNumbers}
                        />
                      </div>
                    </Col>
} */}

                  {!showExamples ? (
                    <Nav
                      style={{
                        // display: "flex",
                        // alignItems: "center",
                        // justifyContent:"center",

                        borderRadius: '5px',
                        margin: 0,
                        padding: 0,
                        background: '#FFDBAA',
                        opacity: 0.5,
                        // // border: '1px solid red',
                        width: '100%',
                      }}
                      className="center-tabs"
                      tabs={true}>
                      {meal?.otherOptions?.map((item, index) => (
                        <NavItem key={index}>
                          <NavLink
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                            }}
                            // className="center-tab-link"
                            // href="javascript:void(0);"
                            className={classnames({
                              active: selectedOption === index,
                            })}
                            onClick={() => {
                              // // console.log('fff');
                              changeNav(index);
                              // setSelectedOption(index)
                            }}>
                            Option {index + 1}
                          </NavLink>
                          {selectedOption === index && selectedOption != 0 && (
                            <span
                              onClick={() => {
                                // // console.log("ccc");
                                deleteOption();
                                changeNav(index, true);
                              }}
                              style={{
                                backgroundColor: 'red',
                                position: 'absolute',
                                top: 190,
                                //  left: 350,
                                transform: 'translateX(-20px)',
                                fontSize: '15px',
                                textAlign: 'center',
                                //  border:"5px solid red",
                                //  width: "26px",
                                padding: '1px 5px',
                                cursor: 'pointer',
                                cursor:
                                  selectedOption === index
                                    ? 'pointer'
                                    : 'not-allowed',
                                borderRadius: '25%',
                                // padding: '0px 6px',
                              }}>
                              <MinusOutlined
                                style={{
                                  opacity: 1,
                                  color: 'White',
                                  fontWeight: 700,
                                }}
                              />
                            </span>
                          )}
                        </NavItem>
                      ))}
                      <NavItem>
                        <NavLink
                          className="center-tab-link"
                          href="javascript:void(0);"
                          onClick={addOption}>
                          <span
                            style={{
                              backgroundColor: 'green',
                              color: 'white',
                              borderRadius: '5px',
                              padding: '0 5px',
                            }}>
                            +
                          </span>
                        </NavLink>
                        {/* <NavLink
                          className="center-tab-link"
                          href="javascript:void(0);"
                          onClick={addOption}>
                          -
                        </NavLink> */}
                      </NavItem>
                    </Nav>
                  ) : null}
                  {(examples && meal.mealPlan?.id === 'daily') ||
                  showExamples ? (
                    <Nav
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '5px',
                        margin: 0,
                        padding: 0,
                        background: '#EEEDEB',
                        // // border: '1px solid red',
                        width: '100%',
                      }}
                      className="center-tabs"
                      tabs={true}>
                      {examples?.map((item, index) => (
                        <NavItem key={index}>
                          <NavLink
                            //  className="center-tab-link"
                            href="javascript:void(0);"
                            className={classnames({
                              active: activeTab === index,
                            })}
                            onClick={() => {
                              toggle(index);
                            }}>
                            <p
                              style={{
                                // fontSize: activeTab === index ? "20px" : "15px",
                                fontWeight: activeTab === index ? '800' : '200',
                                // background:activeTab === index ? "white" : "gray",
                                color: activeTab === index ? 'orange' : 'black',
                              }}>
                              Jour {index + 1}
                            </p>
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                  ) : null}
                  <TabContent activeTab={activeTab}>
                    {!showExamples &&
                      meal.otherOptions[selectedOption]?.map(
                        (dayItem, dayIndex) => (
                          <TabPane key={dayIndex} tabId={dayIndex}>
                            <div
                              onClick={() => setShowBreakFast(!showBreakFast)}
                              className="meal-time-title">
                              <label
                                id={`ptt-${dayIndex}`}
                                className="center-form-label">
                                Petit déjeuner
                              </label>
                              {checkEmptyProperties(
                                meal.otherOptions[selectedOption][dayIndex]
                                  .mealBreakFast,
                              ) && (
                                <div
                                  style={{
                                    margin: '0 0 15px 10px',
                                    backgroundColor: 'red',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                  }}></div>
                              )}
                              <span
                                className={
                                  showBreakFast
                                    ? 'lnr-chevron-down'
                                    : 'lnr-chevron-right'
                                }
                              />
                            </div>

                            {showBreakFast ? (
                              <>
                                <DragDropContext onDragEnd={breakFastDrag}>
                                  <Droppable
                                    droppableId={`breakfastDrag-${selectedOption}`}>
                                    {provided => (
                                      <ul
                                        className="draggable-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {meal.otherOptions[selectedOption][
                                          dayIndex
                                        ].mealBreakFast.map((item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onBreakFastMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onBreakFastMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onBreakFastWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onBreakFastWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onBreakFastWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                      placeholder=" "
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div
                                                          style={{
                                                            position:
                                                              'relative',
                                                            marginTop: '50px',
                                                          }}
                                                          className="form-group">
                                                          <label
                                                            style={{
                                                              fontFamily:
                                                                'Poppins-Regular',
                                                              fontWeight: '100',
                                                              fontSize: '13px',
                                                              opacity: 1,
                                                              color: 'black',
                                                              position:
                                                                'absolute',
                                                              top: -23,
                                                              left: 5,
                                                            }}>
                                                            Description*
                                                          </label>
                                                          <textarea
                                                            required={false}
                                                            className="centerform-textarea"
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onBreakFastMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            placeholder=" "
                                                            label="Description"
                                                            type="text"
                                                          />
                                                        </div>
                                                        <VisibilityTwoToneIcon
                                                          onClick={() => {
                                                            setshowTextAraa(
                                                              true,
                                                            );
                                                            setbodyTextArea(
                                                              item.description,
                                                            );
                                                          }}
                                                          style={{
                                                            position:
                                                              'absolute',
                                                            top: 10,
                                                            right: 20,
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onBreakFastMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>
                                                    <div
                                                      onClick={() =>
                                                        removeBreakfastItem(
                                                          index,
                                                          dayIndex,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </ul>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                                <div style={{textAlign: 'right'}}>
                                  <div
                                    onClick={() =>
                                      addNewBreakFastInput(dayIndex)
                                    }
                                    className="add-another-item">
                                    <span className="icon-plus" />{' '}
                                    <p>Ajouter un autres</p>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div
                              onClick={() => setShowLunch(!showLunch)}
                              className="meal-time-title">
                              <label
                                id={`dej-${dayIndex}`}
                                className="center-form-label">
                                Déjeuner
                              </label>
                              {checkEmptyProperties(
                                meal.otherOptions[selectedOption][dayIndex]
                                  .mealLunch,
                              ) && (
                                <div
                                  style={{
                                    margin: '0 0 15px 10px',
                                    backgroundColor: 'red',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                  }}></div>
                              )}
                              <span
                                className={
                                  showLunch
                                    ? 'lnr-chevron-down'
                                    : 'lnr-chevron-right'
                                }
                              />
                            </div>
                            {showLunch ? (
                              <>
                                <DragDropContext onDragEnd={lunchDrag}>
                                  <Droppable
                                    droppableId={`lunchDrag-${selectedOption}`}>
                                    {provided => (
                                      <ul
                                        className="draggable-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {meal.otherOptions[selectedOption][
                                          dayIndex
                                        ].mealLunch.map((item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onLunchMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onLunchMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onLunchWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onLunchWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onLunchWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onLunchMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onLunchMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>
                                                    <div
                                                      onClick={() =>
                                                        removeLunchItem(
                                                          index,
                                                          dayIndex,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </ul>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                                <div style={{textAlign: 'right'}}>
                                  <div
                                    onClick={() => addNewLunchInput(dayIndex)}
                                    className="add-another-item">
                                    <span className="icon-plus" />{' '}
                                    <p>Ajouter un autre</p>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            <div
                              onClick={() => setShowDinner(!showDinner)}
                              className="meal-time-title">
                              <label
                                id={`din-${dayIndex}`}
                                className="center-form-label">
                                Dîner
                              </label>

                              {checkEmptyProperties(
                                meal.otherOptions[selectedOption][dayIndex]
                                  .mealDinner,
                              ) && (
                                <div
                                  style={{
                                    margin: '0 0 15px 10px',
                                    backgroundColor: 'red',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                  }}></div>
                              )}

                              <span
                                className={
                                  showDinner
                                    ? 'lnr-chevron-down'
                                    : 'lnr-chevron-right'
                                }
                              />
                            </div>
                            {showDinner ? (
                              <>
                                <DragDropContext onDragEnd={dinnerDrag}>
                                  <Droppable
                                    droppableId={`dinnerDrag-${selectedOption}`}>
                                    {provided => (
                                      <ul
                                        className="draggable-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {meal.otherOptions[selectedOption][
                                          dayIndex
                                        ].mealDinner.map((item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onDinnerMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onDinnerMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onDinnerWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onDinnerWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onDinnerWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onDinnerMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onDinnerMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>

                                                    <div
                                                      onClick={() =>
                                                        removeDinnerItem(
                                                          index,
                                                          dayIndex,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ))}

                                        {provided.placeholder}
                                      </ul>
                                    )}
                                  </Droppable>
                                </DragDropContext>

                                <div style={{textAlign: 'right'}}>
                                  <div
                                    onClick={() => addNewDinnerInput(dayIndex)}
                                    className="add-another-item">
                                    <span className="icon-plus" />{' '}
                                    <p>Ajouter un autre</p>
                                  </div>
                                </div>
                              </>
                            ) : null}

                            <div
                              onClick={() => setShowSnacks(!showSnacks)}
                              className="meal-time-title">
                              <label
                                id={`gri-${dayIndex}`}
                                className="center-form-label">
                                Grignotages
                              </label>
                              {checkEmptyProperties(
                                meal.otherOptions[selectedOption][dayIndex]
                                  .mealSnacks,
                              ) && (
                                <div
                                  style={{
                                    margin: '0 0 15px 10px',
                                    backgroundColor: 'red',
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                  }}></div>
                              )}
                              <span
                                className={
                                  showSnacks
                                    ? 'lnr-chevron-down'
                                    : 'lnr-chevron-right'
                                }
                              />
                            </div>

                            {showSnacks ? (
                              <>
                                <DragDropContext onDragEnd={snacksDrag}>
                                  <Droppable
                                    droppableId={`snacksDrag-${selectedOption}`}>
                                    {provided => (
                                      <ul
                                        className="draggable-list"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}>
                                        {meal.otherOptions[selectedOption][
                                          dayIndex
                                        ].mealSnacks.map((item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onSnacksMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onSnacksMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onSnacksWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onSnacksWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onSnacksWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onSnacksMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onSnacksMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>

                                                    <div
                                                      onClick={() =>
                                                        removeSnacksItem(
                                                          index,
                                                          dayIndex,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </ul>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                                <div style={{textAlign: 'right'}}>
                                  <div
                                    onClick={() => addNewSnacksInput(dayIndex)}
                                    className="add-another-item">
                                    <span className="icon-plus" />{' '}
                                    <p>Ajouter un autre</p>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </TabPane>
                        ),
                      )}
                  </TabContent>
                  {showExamples && meal?.examples ? (
                    <TabContent activeTab={activeTab}>
                      {meal.examples.map((dayItem, dayIndex) => (
                        <TabPane key={dayIndex} tabId={dayIndex}>
                          <div
                            onClick={() => setShowBreakFast(!showBreakFast)}
                            className="meal-time-title">
                            <label className="center-form-label">
                              Petit déjeuner
                            </label>
                            {checkEmptyProperties(
                              meal.examples[dayIndex].mealBreakFast,
                            ) && (
                              <div
                                style={{
                                  margin: '0 0 15px 10px',
                                  backgroundColor: 'red',
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}></div>
                            )}
                            <span
                              className={
                                showBreakFast
                                  ? 'lnr-chevron-down'
                                  : 'lnr-chevron-right'
                              }
                            />
                          </div>

                          {showBreakFast ? (
                            <>
                              <DragDropContext onDragEnd={breakFastDrag}>
                                <Droppable
                                  droppableId={`breakfastDrag-${selectedOption}`}>
                                  {provided => (
                                    <ul
                                      className="draggable-list"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {meal.examples[
                                        dayIndex
                                      ].mealBreakFast.map((item, index) => (
                                        <Draggable
                                          key={`${index}`}
                                          draggableId={`${index}`}
                                          index={index}>
                                          {provided => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}>
                                              <Card className="card-container">
                                                <CardBody>
                                                  <FormGroup row>
                                                    <Col
                                                      style={{zIndex: 10}}
                                                      sm={4}>
                                                      <div className="form-group">
                                                        <DropDown
                                                          value={item.gender}
                                                          onClick={item =>
                                                            onBreakFastMealChange(
                                                              item,
                                                              'gender',
                                                              index,
                                                              dayIndex,
                                                              true,
                                                            )
                                                          }
                                                          data={genders}
                                                          label="Genre*"
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col
                                                      style={{zIndex: 10}}
                                                      sm={4}>
                                                      <div className="form-group">
                                                        <DropDown
                                                          value={item.mealType}
                                                          onClick={item =>
                                                            onBreakFastMealChange(
                                                              item,
                                                              'mealType',
                                                              index,
                                                              dayIndex,
                                                              true,
                                                            )
                                                          }
                                                          label={`Type d'élément*`}
                                                          data={itemTypes}
                                                        />
                                                      </div>
                                                    </Col>

                                                    {item.mealType?.id ===
                                                      'carbs' ||
                                                    item.mealType?.id ===
                                                      'mfe' ? (
                                                      <>
                                                        <Col sm={12}>
                                                          {' '}
                                                          <Row>
                                                            <Col sm={3}>
                                                              <label className="weight-label">
                                                                Poids (KG)
                                                              </label>
                                                            </Col>
                                                            <Col sm={3}>
                                                              <label className="weight-label">
                                                                Dépend de
                                                              </label>
                                                            </Col>
                                                            <Col sm={3}>
                                                              <label className="weight-label">
                                                                Fonction
                                                              </label>
                                                            </Col>
                                                            <Col sm={3}>
                                                              <label className="weight-label">
                                                                Quantité (g)
                                                              </label>
                                                            </Col>
                                                          </Row>
                                                        </Col>
                                                        {item.weights.map(
                                                          (item2, index2) => (
                                                            <Col
                                                              key={index2}
                                                              sm={12}>
                                                              <Row>
                                                                <Col sm={3}>
                                                                  <label className="weight-label weight-label2">
                                                                    +{item2.id}
                                                                  </label>
                                                                </Col>
                                                                <Col
                                                                  style={{
                                                                    zIndex:
                                                                      item
                                                                        .weights
                                                                        .length -
                                                                      index2,
                                                                  }}
                                                                  sm={3}>
                                                                  <DropDown
                                                                    value={
                                                                      item2.valueType
                                                                    }
                                                                    onClick={item =>
                                                                      onBreakFastWeightChange(
                                                                        item,
                                                                        'valueType',
                                                                        index,
                                                                        index2,
                                                                        dayIndex,
                                                                        true,
                                                                      )
                                                                    }
                                                                    data={
                                                                      dependings
                                                                    }
                                                                    label=""
                                                                  />
                                                                </Col>
                                                                <Col
                                                                  style={{
                                                                    zIndex:
                                                                      item
                                                                        .weights
                                                                        .length -
                                                                      index2,
                                                                  }}
                                                                  sm={3}>
                                                                  <DropDown
                                                                    value={
                                                                      item2.weightFunction
                                                                    }
                                                                    disabled={
                                                                      item2
                                                                        .valueType
                                                                        ?.id !==
                                                                      'weight'
                                                                    }
                                                                    onClick={item =>
                                                                      onBreakFastWeightChange(
                                                                        item,
                                                                        'weightFunction',
                                                                        index,
                                                                        index2,
                                                                        dayIndex,
                                                                        true,
                                                                      )
                                                                    }
                                                                    data={
                                                                      functions
                                                                    }
                                                                    label=""
                                                                  />
                                                                </Col>
                                                                <Col sm={3}>
                                                                  <Input
                                                                    value={
                                                                      item2.value
                                                                    }
                                                                    pattern="[0-9]"
                                                                    onChange={e =>
                                                                      onBreakFastWeightChange(
                                                                        e.target
                                                                          .value,
                                                                        'value',
                                                                        index,
                                                                        index2,
                                                                        dayIndex,
                                                                        true,
                                                                      )
                                                                    }
                                                                    label=""
                                                                    type="number"
                                                                    placeholder=" "
                                                                  />
                                                                </Col>
                                                              </Row>
                                                            </Col>
                                                          ),
                                                        )}
                                                      </>
                                                    ) : null}
                                                    <Col sm={12}>
                                                      <div className="form-group">
                                                        <Input
                                                          value={
                                                            item.description
                                                          }
                                                          pattern=".*\S+.*"
                                                          onChange={e =>
                                                            onBreakFastMealChange(
                                                              e.target.value,
                                                              'description',
                                                              index,
                                                              dayIndex,
                                                              true,
                                                            )
                                                          }
                                                          placeholder=" "
                                                          label="Description"
                                                          type="text"
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col sm={12}>
                                                      <div className="form-group">
                                                        <Input
                                                          value={item.notes}
                                                          placeholder=" "
                                                          pattern=".*\S+.*"
                                                          onChange={e =>
                                                            onBreakFastMealChange(
                                                              e.target.value,
                                                              'notes',
                                                              index,
                                                              dayIndex,
                                                              true,
                                                            )
                                                          }
                                                          label="Remarques"
                                                          type="text"
                                                        />
                                                      </div>
                                                    </Col>
                                                  </FormGroup>
                                                  <div
                                                    onClick={() =>
                                                      removeBreakfastItem(
                                                        index,
                                                        dayIndex,
                                                        true,
                                                      )
                                                    }
                                                    className="add-another remove-button">
                                                    <span className="icon-minus" />
                                                  </div>
                                                </CardBody>
                                              </Card>
                                            </li>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <div style={{textAlign: 'right'}}>
                                <div
                                  onClick={() =>
                                    addNewBreakFastInput(dayIndex, true)
                                  }
                                  className="add-another-item">
                                  <span className="icon-plus" />{' '}
                                  <p>Ajouter un autre</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div
                            onClick={() => setShowLunch(!showLunch)}
                            className="meal-time-title">
                            <label className="center-form-label">
                              Déjeuner
                            </label>
                            {checkEmptyProperties(
                              meal.examples[dayIndex].mealLunch,
                            ) && (
                              <div
                                style={{
                                  margin: '0 0 15px 10px',
                                  backgroundColor: 'red',
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}></div>
                            )}
                            <span
                              className={
                                showLunch
                                  ? 'lnr-chevron-down'
                                  : 'lnr-chevron-right'
                              }
                            />
                          </div>
                          {showLunch ? (
                            <>
                              <DragDropContext onDragEnd={lunchDrag}>
                                <Droppable
                                  droppableId={`lunchDrag-${selectedOption}`}>
                                  {provided => (
                                    <ul
                                      className="draggable-list"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {meal.examples[dayIndex].mealLunch.map(
                                        (item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onLunchMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onLunchMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onLunchWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onLunchWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onLunchWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onLunchMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onLunchMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>
                                                    <div
                                                      onClick={() =>
                                                        removeLunchItem(
                                                          index,
                                                          dayIndex,
                                                          true,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ),
                                      )}
                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <div style={{textAlign: 'right'}}>
                                <div
                                  onClick={() =>
                                    addNewLunchInput(dayIndex, true)
                                  }
                                  className="add-another-item">
                                  <span className="icon-plus" />{' '}
                                  <p>Ajouter un autre</p>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div
                            onClick={() => setShowDinner(!showDinner)}
                            className="meal-time-title">
                            <label className="center-form-label">Dîner</label>
                            {checkEmptyProperties(
                              meal.examples[dayIndex].mealDinner,
                            ) && (
                              <div
                                style={{
                                  margin: '0 0 15px 10px',
                                  backgroundColor: 'red',
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}></div>
                            )}
                            <span
                              className={
                                showDinner
                                  ? 'lnr-chevron-down'
                                  : 'lnr-chevron-right'
                              }
                            />
                          </div>
                          {showDinner ? (
                            <>
                              <DragDropContext onDragEnd={dinnerDrag}>
                                <Droppable
                                  droppableId={`dinnerDrag-${selectedOption}`}>
                                  {provided => (
                                    <ul
                                      className="draggable-list"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {meal.examples[dayIndex].mealDinner.map(
                                        (item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onDinnerMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onDinnerMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onDinnerWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onDinnerWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onDinnerWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onDinnerMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onDinnerMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>

                                                    <div
                                                      onClick={() =>
                                                        removeDinnerItem(
                                                          index,
                                                          dayIndex,
                                                          true,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ),
                                      )}

                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>

                              <div style={{textAlign: 'right'}}>
                                <div
                                  onClick={() =>
                                    addNewDinnerInput(dayIndex, true)
                                  }
                                  className="add-another-item">
                                  <span className="icon-plus" />{' '}
                                  <p>Ajouter un autre</p>
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div
                            onClick={() => setShowSnacks(!showSnacks)}
                            className="meal-time-title">
                            <label className="center-form-label">
                              Grignotages
                            </label>
                            {checkEmptyProperties(
                              meal.examples[dayIndex].mealSnacks,
                            ) && (
                              <div
                                style={{
                                  margin: '0 0 15px 10px',
                                  backgroundColor: 'red',
                                  width: '10px',
                                  height: '10px',
                                  borderRadius: '50%',
                                }}></div>
                            )}
                            <span
                              className={
                                showSnacks
                                  ? 'lnr-chevron-down'
                                  : 'lnr-chevron-right'
                              }
                            />
                          </div>
                          {showSnacks ? (
                            <>
                              <DragDropContext onDragEnd={snacksDrag}>
                                <Droppable
                                  droppableId={`snacksDrag-${selectedOption}`}>
                                  {provided => (
                                    <ul
                                      className="draggable-list"
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}>
                                      {meal.examples[dayIndex].mealSnacks.map(
                                        (item, index) => (
                                          <Draggable
                                            key={`${index}`}
                                            draggableId={`${index}`}
                                            index={index}>
                                            {provided => (
                                              <li
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}>
                                                <Card
                                                  key={index}
                                                  className="card-container">
                                                  <CardBody>
                                                    <FormGroup row>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={item.gender}
                                                            onClick={item =>
                                                              onSnacksMealChange(
                                                                item,
                                                                'gender',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            data={genders}
                                                            label="Genre*"
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col
                                                        style={{zIndex: 10}}
                                                        sm={4}>
                                                        <div className="form-group">
                                                          <DropDown
                                                            value={
                                                              item.mealType
                                                            }
                                                            onClick={item =>
                                                              onSnacksMealChange(
                                                                item,
                                                                'mealType',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label={`Type d'élément*`}
                                                            data={itemTypes}
                                                          />
                                                        </div>
                                                      </Col>

                                                      {item.mealType?.id ===
                                                        'carbs' ||
                                                      item.mealType?.id ===
                                                        'mfe' ? (
                                                        <>
                                                          <Col sm={12}>
                                                            {' '}
                                                            <Row>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Poids (KG)
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Dépend de
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Fonction
                                                                </label>
                                                              </Col>
                                                              <Col sm={3}>
                                                                <label className="weight-label">
                                                                  Quantité (g)
                                                                </label>
                                                              </Col>
                                                            </Row>
                                                          </Col>
                                                          {item.weights.map(
                                                            (item2, index2) => (
                                                              <Col
                                                                key={index2}
                                                                sm={12}>
                                                                <Row>
                                                                  <Col sm={3}>
                                                                    <label className="weight-label weight-label2">
                                                                      +
                                                                      {item2.id}
                                                                    </label>
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.valueType
                                                                      }
                                                                      onClick={item =>
                                                                        onSnacksWeightChange(
                                                                          item,
                                                                          'valueType',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        dependings
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col
                                                                    style={{
                                                                      zIndex:
                                                                        item
                                                                          .weights
                                                                          .length -
                                                                        index2,
                                                                    }}
                                                                    sm={3}>
                                                                    <DropDown
                                                                      value={
                                                                        item2.weightFunction
                                                                      }
                                                                      disabled={
                                                                        item2
                                                                          .valueType
                                                                          ?.id !==
                                                                        'weight'
                                                                      }
                                                                      onClick={item =>
                                                                        onSnacksWeightChange(
                                                                          item,
                                                                          'weightFunction',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      data={
                                                                        functions
                                                                      }
                                                                      label=""
                                                                    />
                                                                  </Col>
                                                                  <Col sm={3}>
                                                                    <Input
                                                                      value={
                                                                        item2.value
                                                                      }
                                                                      pattern="[0-9]"
                                                                      onChange={e =>
                                                                        onSnacksWeightChange(
                                                                          e
                                                                            .target
                                                                            .value,
                                                                          'value',
                                                                          index,
                                                                          index2,
                                                                          dayIndex,
                                                                          true,
                                                                        )
                                                                      }
                                                                      label=""
                                                                      type="number"
                                                                    />
                                                                  </Col>
                                                                </Row>
                                                              </Col>
                                                            ),
                                                          )}
                                                        </>
                                                      ) : null}
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={
                                                              item.description
                                                            }
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onSnacksMealChange(
                                                                e.target.value,
                                                                'description',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Description"
                                                            type="text"
                                                            placeholder=" "
                                                          />
                                                        </div>
                                                      </Col>
                                                      <Col sm={12}>
                                                        <div className="form-group">
                                                          <Input
                                                            value={item.notes}
                                                            placeholder=" "
                                                            pattern=".*\S+.*"
                                                            onChange={e =>
                                                              onSnacksMealChange(
                                                                e.target.value,
                                                                'notes',
                                                                index,
                                                                dayIndex,
                                                                true,
                                                              )
                                                            }
                                                            label="Remarques"
                                                            type="text"
                                                          />
                                                        </div>
                                                      </Col>
                                                    </FormGroup>

                                                    <div
                                                      onClick={() =>
                                                        removeSnacksItem(
                                                          index,
                                                          dayIndex,
                                                          true,
                                                        )
                                                      }
                                                      className="add-another remove-button">
                                                      <span className="icon-minus" />
                                                    </div>
                                                  </CardBody>
                                                </Card>
                                              </li>
                                            )}
                                          </Draggable>
                                        ),
                                      )}
                                      {provided.placeholder}
                                    </ul>
                                  )}
                                </Droppable>
                              </DragDropContext>
                              <div style={{textAlign: 'right'}}>
                                <div
                                  onClick={() =>
                                    addNewSnacksInput(dayIndex, true)
                                  }
                                  className="add-another-item">
                                  <span className="icon-plus" />{' '}
                                  <p>Ajouter un autre</p>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </TabPane>
                      ))}
                    </TabContent>
                  ) : null}
                </Row>
              </Col>
              <Col sm={12}>
                <label className="center-form-label">Recommandations</label>
                <div className="form-group">
                  <div style={{marginBottom: '100px', width: '100%'}}>
                    <Editor
                      editorState={meal.recommendations}
                      wrapperClassName="target-wrapper"
                      editorClassName="target-editor"
                      onEditorStateChange={value =>
                        onInputChange(value, 'recommendations')
                      }
                    />
                  </div>
                </div>
              </Col>

              <div
                style={{
                  // height:"60px",
                  // bottom: '7px',
                  width: '99%',
                  // border:"1px solid red",
                  right: '50px',
                  position: '-webkit-sticky',
                  position: 'sticky',
                  // float: "right",
                  bottom: '80px',
                  right: '0px',
                  zIndex: '999',
                  // marginTop: '180px',
                }}
                // {findObjectsWithEmptyFields(meal.otherOptions[0])
                // .completenessPercentage < 100.0}
                className="save-btn-container">
                {show == true ? (
                    (
                      <Button
                        // style={{width: 200, height: 60}}
                        disabled={loading}
                        type="submit"
                        className="profile-btn-orange"
                        style={{
                          zIndex: '9999',
    
                          animation: 'shwadow 4s ',
                        }}
                        // className={`btn-wide center-form-save-btn mb-2  ${
                        //   !meal.name ? 'disabledBtn' : ''
                        // }`}
                        // size="lg"
                      >
                        {loading || loadingDataEdit ? (
                          <span style={{marginRight: '.5rem'}}>
                            <Spinner
                              as="span"
                              variant="warning"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              animation="grow"
                            />{' '}
                          </span>
                        ) : null}
                        Valider
                      </Button>
                    )
                ) :
                (
                  <Button
                    // style={{width: 200, height: 60}}
                    disabled={loading}
                    type="submit"
                    className="profile-btn-orange"
                    style={{
                      zIndex: '9999',

                      animation: 'shwadow 4s ',
                    }}
                    // className={`btn-wide center-form-save-btn mb-2  ${
                    //   !meal.name ? 'disabledBtn' : ''
                    // }`}
                    // size="lg"
                  >
                    {loading || loadingDataEdit ? (
                      <span style={{marginRight: '.5rem'}}>
                        <Spinner
                          as="span"
                          variant="warning"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          animation="grow"
                        />{' '}
                      </span>
                    ) : null}
                    {customMenuUserId
                      ? 'Valider'
                      : mealData
                      ? 'Éditer Régime'
                      : 'Ajouter Régime'}
                  </Button>
                )}
              </div>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Modal size="lg" isOpen={showTextAraa}>
        <ModalHeader
          style={{marginTop: '1rem'}}
          close={
            <button className="close" onClick={() => setshowTextAraa(false)}>
              &times;
            </button>
          }>
          Description
        </ModalHeader>
        <Form>
          <ModalBody style={{minHeight: '5rem', margin: '1rem'}}>
            {bodyTextArea}
          </ModalBody>
          <ModalFooter style={{marginBottom: '5px'}}>
            <Button
              className="profile-btn-orange"
              style={{width: '150px'}}
              onClick={() => setshowTextAraa(false)}>
              Ok
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        size="lg"
        isOpen={showError}
        // toggle={() => setShowCancelForfait(!showCancelForfait)}
      >
        <ModalHeader
        // toggle={
        //   () => {
        //     setcountError(0);
        //     setshowError(false);
        //   }
        // }
        // close={
        //   <button
        //     className="close"
        //     onClick={() => {
        //       setcountError(0);
        //       setshowError(false);
        //     }}>
        //     &times;
        //   </button>
        // }
        >
          Plan alimentaire : Attention
        </ModalHeader>

        <ModalBody>
          <p style={{color: 'red', fontWeight: 700}}>{countError} erreus !</p>
          <p>SVP remplir le(s) shamp(s) suivant(s):</p>
          <div
            style={{
              overflowY: 'scroll',
              height: countError > 30 ? '80vh' : 'none',
              display: 'flex',
              gap: 10,
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}>
            {eroors.map((data, i) => (
              <p
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#FFEBEB',
                  border: '1px solid red',
                  borderRadius: '5px',
                  padding: '5px',
                }}
                onClick={() => {
                  setshowError(false);

                  // // console.log(data);
                  // // console.log(meal.mealPlan);
                  if (meal.mealPlan.id != 'daily') {
                    // console.log(data.index);
                    changeNav(data.index);
                    openSection(data.section);
                  } else {
                    // console.log('hh',data.index);
                    // console.log('hh',data.page);
                    changeNav(data.page);
                    toggle(data.index);
                    openSection(data.section);
                  }

                  setcountError(0);
                  setshowError(false);
                  setEroors([]);
                  // scrollToId(`${data.section}${data.index}`);
                }}
                className="modalMessage"
                key={i}>
                {' '}
                {data.message}
                <br></br>{' '}
              </p>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="link"
            onClick={() => {
              setshowError(false);
              setEroors([]);
              setcountError(0);
            }}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default MealComponent;
