import React, {useEffect, useRef} from 'react';
import ReactECharts from 'echarts-for-react';
import {option, optionW} from 'shared/optionCarts';
import {optionDate, optionsT} from 'shared/optionCartsDate';
import {
  all_weights,
  applyChecker,
  applyChecker2,
  applyCheckerNuller,
  applyColorWO,
  applyColorWOsecond,
  checkSuccessive,
  diffDate,
  diffDateW,
  equalizeArrayLengths,
  expandArray,
  
  getAllMondays,
  
  getAllMondaysVac,
  
  getDateFromDay,
  
  getFirstDayOfWeek,
  
  getFirstDayOfWeek2,
  getFirstDayOfWeekk,
  getMondaysBetweenDates,
  getStartOfWeeksOfYearFromNow2v,
  getStartOfWeeksOfYearFromNow2vPre,
  ifTOdayG,
  removeDuplicateMealDates,
  removeDuplicateMeals,
  replaceNullsAndUndefined,
} from 'shared/extra';
import html2canvas from 'html2canvas';
import {VerticalAlignBottomOutlined} from '@ant-design/icons';
import CircleComponent from 'DemoPages/Elements/points';

function getStartOfWeeksOfYearFromNow(td) {
  //  // console.log(today);
  const today = new Date(td);
  const currentYear = today.getFullYear();
  const currentWeek = getWeekNumber(today);
  // // console.log(currentWeek);
  const weeks = [];
  let mealWeek = 0;
  // Loop through each week of the year starting from the current week
  for (let i = currentWeek; i <= 52; i++) {
    mealWeek++;
    const date = new Date(currentYear, 0, 8 + (i - 2) * 7);
    const dd = String(date.getDate()).padStart(1, '0');
    const mm = String(date.getMonth() + 1).padStart(1, '0');
    const weekStart = `S${mealWeek} \n ${dd}/${mm}${
      mm == 1 || mealWeek == 1 ? ' • ' + date.getFullYear() : ''
    }`;
    weeks.push(weekStart);
  }
  // // console.log(weeks[0]);
  return weeks;
}


function mergeAndSortDates(arr1, arr2) {
  // Combine the two arrays
  let mergedArray = [...arr1, ...arr2];

  // Remove duplicates by converting to a Set and back to an array
  mergedArray = [...new Set(mergedArray)];

  // Sort the array of dates
  mergedArray.sort((a, b) => new Date(a) - new Date(b));

  return mergedArray;
}




//thisone
function getStartOfWeek(date, firstDayOfWeek = 1) {
  const givenDate = moment(date);
  const dayOfWeek = givenDate.day();
  const difference = (dayOfWeek < firstDayOfWeek) ?
    (dayOfWeek - firstDayOfWeek + 7) : (dayOfWeek - firstDayOfWeek);

  givenDate.subtract(difference, 'days');
  givenDate.startOf('day'); // Set time to midnight for the start of the day
  return givenDate.toDate();
}

//  2 - ref day | // ? phy : na7i poin zayda 
//  !  1- "meal_date_without": "2024-07-09",>= date lyoum  => true sinon false simiP/digital

function findMatches(meal_date, startWeeks,type) {
  // console.log(meal_date);

  // // console.log(startWeeks);
  // // console.log(startWeeks.map(week =>type==="premium"?true: meal_date.includes(week)));
  return startWeeks.map( week =>meal_date?meal_date?.includes(week):false);

}


function findMatches2(meal_date, startWeeks,type) {
  // // console.log(meal_date);
  // // console.log(startWeeks.map(week => meal_date.includes(week)));
  // // console.log(startWeeks.map(week => type==="premium"?true: meal_date.includes(week)));
  return startWeeks.map(week => meal_date.includes(week));
}

const Page = ({ dataW, objective, label, maxMin, area, name, weightDataObj,vacation,type="none",refDay,sub }) => {
  // console.log('xxx',sub);
  
  sub == null;
 

const paymentOfweek=getAllMondays(sub)
// console.log("nnnnnnm",paymentOfweek);

  let  filtredVacationbefore=[]
  filtredVacationbefore = vacation.length > 0 ? vacation.map(data => getFirstDayOfWeek2(new Date(data.from))):['']
  
  // console.log(filtredVacationbefore);
  let filtredVacation = removeDuplicateMealDates(filtredVacationbefore)
  // console.log(filtredVacation);

  let datas = dataW?.filter(d => d.weightRef == true).reverse()
  let data = removeDuplicateMeals(datas)
  // console.log("ggg",data);
  const withoutLast = data[data.length - 1]?.meal_date_without
  
    const toDaygreathenWithout=ifTOdayG(new Date().toISOString().split("T")[0],withoutLast)
// // console.log(toDaygreathenWithout);
//   const fillterDayWithout = data?.filter(day => {
  const vacanceCheker = filtredVacation[filtredVacation.length - 1] === data[data.length - 1]?.meal_date
  // console.log(vacanceCheker);
  // console.log(((!toDaygreathenWithout) && (data.length > 1 )&& (type != "premium") && (vacanceCheker)));
  // console.log(data.length);
  if ((!toDaygreathenWithout) && (data.length > 1 )&& (type != "premium") && (vacanceCheker)) {
    // console.log('ff');
    data?.pop()
  }
// // console.log(data.length);

// // console.log(fillterDayWithout);


  const seriesStartDay = data[0]?.meal_date;
  let last = data[data.length - 1]?.meal_date;


 


//  const prum=data?.map(data=>new Date(data.startOfWeek).toISOString().split("T")[0])
  // // console.log(vacanceCheker);
  
  const dayPerPoint=  mergeAndSortDates(data?.map(data=>new Date(data.startOfWeek).toISOString().split("T")[0]), getAllMondaysVac(vacation)) 
  // console.log(dayPerPoint);
  
  let listeOfWeeksOnX = type == "premium" ? vacation.length > 0 ?
    // getStartOfWeeksOfYearFromNow2vPre(seriesStartDay)
    dayPerPoint
    : data?.map(data => new Date(data.startOfWeek).toISOString().split("T")[0]) : type != "none" ? getStartOfWeeksOfYearFromNow2v(
    seriesStartDay,
    last,
    sub ? sub[sub.length - 1]?.expiration_date : null,
    data[data.length - 1]?.meal_date_without,
    type == "standard" ? [] : paymentOfweek) : [];
  
  // console.log(listeOfWeeksOnX);
  // // console.log("mmm",listeOfWeeksOnX);
  const verified = getDateFromDay(refDay, listeOfWeeksOnX[listeOfWeeksOnX.length - 1])
  // console.log(verified);
  
  const todayPassRefDay = ifTOdayG(new Date(), verified)
  // console.log(todayPassRefDay);
  // // console.log("mmm",listeOfWeeksOnX.length);
  // 
  // cosnt startOfWeek=data[data.length - 1]?.startOfWeek
  
  
  // console.log("mmm",data[data.length - 1]?.startOfWeek?.split('T')[0]);
  // console.log("mmm",getFirstDayOfWeek2(new Date(data[data.length - 1]?.meal_date?.split('T')[0])));
  
  const checkerExist = getFirstDayOfWeek2(new Date(data[data.length - 1]?.meal_date?.split('T')[0])) === listeOfWeeksOnX[listeOfWeeksOnX.length - 1]
  // console.log(checkerExist);
  // console.log(toDaygreathenWithout);
  // console.log("mmm",listeOfWeeksOnX.length);

  if (listeOfWeeksOnX.length > 1 && type != "premium"  && !todayPassRefDay &&!checkerExist) listeOfWeeksOnX?.pop()
  
  // console.log("mmm",listeOfWeeksOnX.length);




  const listeOfmeal_dates = data.map(datas => datas.meal_date);
  


  const weights = data.map(datas => datas.weight);
// console.log(weights);

  // console.log(data.length);
  
// const DatePerPoint = dayPerPoint.map(d => d)
// console.log(dayPerPoint);
// // console.log(DatePerPoint);


// console.log(listeOfmeal_dates);
  const newWeightRow = applyCheckerNuller(weights, findMatches(listeOfmeal_dates, listeOfWeeksOnX),true);
  // console.log(newWeightRow);
  // console.log(listeOfWeeksOnX);
  

  const newlisteOfWeeksOnX = newWeightRow.map((item, index) => (item !== 'null' ? listeOfWeeksOnX[index] : "null")).filter(item => item !== "null");
  // console.log(newlisteOfWeeksOnX);
  const finalenewWeightRow=newWeightRow.filter(d=>d!='null') 
  
  
    // // console.log(weights);
    // console.log("mm",weights);
  //!----------------------------------- vacation
  
  

  
    const listOfVacation = getAllMondaysVac(vacation)
    // console.log(listOfVacation);
    const merge = mergeAndSortDates(listOfVacation, newlisteOfWeeksOnX)
  // console.log(merge);
  // console.log(dayPerPoint);
  
  
  const fm = findMatches(listOfVacation, merge, type)
  // console.log(fm);
  // console.log(findMatches(listeOfmeal_dates, merge));
  
  const newW  = applyChecker(weights, findMatches(listeOfmeal_dates, merge),true);
  
  // console.log(newW);
  
  
  
  
  
  
  
  
  
  
  
  //!--------------------------------







  const weeksNumber = data?.map(datas => datas.weekNumberAgenda);
 
  // const newData = newWeightRow.slice(0, findMatches(x, sWeek).length);

  let checkerTOStart = weightDataObj[0]?.goal;
  // console.log("yyyy",checkerTOStart);
  const xObj = weightDataObj?.map(datas => datas.meal_date || '2024-05-13');
  // // console.log(xObj);
  const objWeightsf =  weightDataObj?.map(datas =>  datas.weight);
const objWeights=objWeightsf;

// console.log(objWeights);
  
  if (objWeights.length < weights.length) {
    for (let i = 0; i <= weights.length - objWeights.length; i++){
      // // console.log();
      objWeights.push(objWeights[objWeights.length-1])
    }
  }
  // // console.log(findMatches2(xObj, listeOfWeeksOnX,type));
  
  const appliedChecker = applyChecker(objWeights, findMatches2(xObj, listeOfWeeksOnX,type));
  // console.log(appliedChecker);
  const appliedCheckerWithoutNull = appliedChecker.map((value,i) => {
    if (!value) {
    return appliedChecker[i+1]||appliedChecker[i-1]||appliedChecker[i]
    } else {
      return value
  }
})
// console.log(dataW);
const filterzdColor=dataW?.map(data=>data.goal=="improving health" ? "imp" : "los")
let fixed=[]
   fixed = applyColorWOsecond(filterzdColor);
  // console.log(fixed);
  
  
// console.log(appliedCheckerWithoutNull);
  const objWeightsColor = applyColorWO(appliedCheckerWithoutNull);
  // console.log('objWeightsColor', objWeightsColor)
  // // console.log(findMatches(filtredVacation[filtredVacation.length-1], listeOfWeeksOnX,type));
  
  // console.log(objWeightsColor.length - fixed.length);
  
 let fixedNew=[]
 if (fixed.length < objWeightsColor.length) {
   for (let i = 0; i <= objWeightsColor.length - fixed.length; i++){
     // // console.log();
     fixed.push(fixed[fixed.length-1])
   }
  }
  // console.log(fixed);
// console.log(filtredVacation);
  // console.log(findMatches(filtredVacation, listeOfWeeksOnX, type));
  
  const finIndex = merge.indexOf(getFirstDayOfWeek2(new Date()));
  // console.log(merge);
  
  // console.log(getFirstDayOfWeek2(new Date()));
  
// console.log(finIndex);



 const {arrO,arrW}=equalizeArrayLengths({arrO:appliedCheckerWithoutNull,arrW:type=="premium"?listeOfWeeksOnX: finIndex==-1 ?merge.slice(0,merge.length+1):merge.slice(0,finIndex+1)})
  
  return (
    <div
      // ref={divRef}
      style={{
        padding: 50,
        position: 'relative',
      }}>
      <div
        title="légende"
        
        style={{
          fontWeight: 500,
          display: 'flex',
          fontSize: 40,
          height:"50px",
          borderRadius: '5px',
          width: "190px",
          height: "auto",
          color: 'black',
          flexWrap:"wrap",
          border:"1px solid #0000001",
          background: '#faebd791',
          justifyContent: 'space-between',
          alignItems: 'center',
          
          // textDecoration: 'underline',
          cursor: 'auto',
          position: 'absolute',
          padding:"5px 10px",
          right: 20,
          top: -40,
        }}>
        {/* <div style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}> */}

        <CircleComponent color={'#ffa500'} text={"Poids de semaine"} />  
        {/* <CircleComponent color={'#03AED2'} text={"Poids non renseigné"} />   */}
       {<CircleComponent color={'#03AED2'} text={"Mode vacance"} />  }
        <CircleComponent color={'#41B06E'} text={"Poids Objectif"} />  
        <CircleComponent color={'#640D6B'} text={"Poids de forme"} />  
    
    
        {/* </div> */}
        
      </div>



      {/* <span
        title="Capture d'écran"
        onClick={captureScreenshot}
        style={{
          fontWeight: 500,
          display: 'flex',
          fontSize: 40,
          borderRadius: '5px',
          color: 'white',
          background: 'orange',
          justifyContent: 'space-between',
          alignItems: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
          position: 'absolute',
          right: 50,
          top: -40,
        }}>
        <VerticalAlignBottomOutlined />{' '}
      </span> */}
      <ReactECharts
        // ref={chartRef}
        notMerge={true}
        lazyUpdate={true}
        id="chart"
        style={{
          cursor:"pointer",
          height: '50vh',
          zoom: 1.25,
          // transform: 'scale(.9)',
          // transformOrigin: "20% 20%"
        }}
        option={
          // option
          optionW({
            type,
            // weeks:type=="premium"?listeOfWeeksOnX: finIndex==-1 ?merge.slice(0,merge.length+1):merge.slice(0,finIndex+1),
            weeks:arrW,
            weights:type=="premium"?newW:replaceNullsAndUndefined(newW),
            label,
            maxMin,
            area,
            weeksNumber,
            // colorWeight: findMatches(listeOfmeal_dates, listeOfWeeksOnX),
            colorWeight:fm,
              // findMatches(filtredVacation, listeOfWeeksOnX, type),
            // objWeights:appliedCheckerWithoutNull,
            objWeights:arrO,
            // fixed,
            objWeightsColor:fixed,
            checkerFormOrObj:checkerTOStart,
           
          })
          // optionW(sWeek, w, objective ? objective : 50, label, maxMin, area,c)
        }
      />
    </div>
  );
};

export default Page;

// const options = {
//   grid: { top: 8, right: 8, bottom: 24, left: 36 },
//   xAxis: {
//     type: 'category',
//     data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
//   },
//   yAxis: {
//     type: 'value',
//   },
//   series: [
//     {
//       data: [820, 932, 901, 934, 1290, 1330, 1320],
//       type: 'line',
//       smooth: true,
//     },
//   ],
//   tooltip: {
//     trigger: 'axis',
//   },
// };
