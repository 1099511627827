import {Button, ConfigProvider} from 'antd';
import React from 'react';

// export const customValidationMessage = 'ce champ est obligatoire!'; // Customize the validation message here
const ButtonAntDesign = ({
  style,
  onClick,
  color,
   icon,
  colorBgContainer,
  text,
  MinWidth,
  ...props
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: color,
          borderRadius: 5,
        
          // colorBorder: "black",

          // Alias Token
          colorBgContainer: colorBgContainer,
        },
      }}>
      <Button
      
      
        {...props} onClick={onClick} style={{ minWidth: MinWidth?MinWidth:"250px",...style }}>
      <span> {icon?icon:""} {text}</span>
      </Button>
    </ConfigProvider>
  );
};

export default ButtonAntDesign;
